import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGIN_TV_SUCCESS,
    LOGIN_TV_FAIL
} from './type'
import axios from 'axios'
import {URL_LOGIN} from "../../utils/GlobalURL"
import SetToken from '../../utils/SetToken' 
import { notification } from "antd";

export const LoginAdmin = (data) => (dispatch) => {
    axios.post(`${URL_LOGIN}/login` , data)
        .then(res => dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        }))
        .catch(err => {dispatch({
            type: LOGIN_FAIL,
            payload: err.response
        })
        notification['error']({
            message: 'Login Failed !!',
            description:
              'The userName and Password you entred did not match our records. Please double-check and try again',
          })
    
    })

}
export const AddTvs = (data) => (dispatch) => {
    // console.log("data", data)
    SetToken()
    axios.post(`${URL_LOGIN}/register`, data)
        .then(res =>{
// console.log("res", res)
            dispatch({
                type: LOGIN_TV_SUCCESS,
                payload: res.data
    
            })
            notification["success"]({
                message:
                    "Success !!",
                description: "",
            })
        } )
        .catch(err =>{
            if (err.response.status === 401) {
                window.location = "/"
           }
           return err,
            // console.log(err)
            dispatch({
                type: LOGIN_TV_FAIL,
                payload: err.response
            }),
            notification["error"]({
                message:
                    "failed !!",
                description: "",
            })
        } )

   

}
