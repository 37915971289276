import "./style.css"
import React from 'react'

function Spinner(props) {
  return (
    <div className="overlay">
        <div className="spinner">
           {props.children}
        </div>
    </div>
  )
}

export default Spinner