import React, { useState, useEffect } from 'react'
import { Button, Col, Upload, Row, message, notification, Progress } from "antd";
// import playBtn from "../../../Assets/icons/play-button.svg"
// import pauseBTN from "../../../Assets/icons/pause.svg"
import { UploadMusic, PlayMusic, PauseMusic } from "../../../Redux/actions/MusicAction"
import { useDispatch, useSelector } from 'react-redux'
import ReactJkMusicPlayer from 'react-jinke-music-player'
import 'react-jinke-music-player/assets/index.css'
import { IconButton } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { UploadOutlined } from '@ant-design/icons';

function AddMusic({ IDTVS, TVs }) {
    // console.log("TVs125", TVs)
    const dispatch = useDispatch()
    const [Percentage, setPercentage] = useState();

    const SelectedTv = JSON.parse(localStorage.getItem("DocSelected"))
    const TvsListId = []
    if (SelectedTv) {
        SelectedTv.map((ex, i) => {
            TvsListId.push(ex.id)
        })
    }
    const MyMusic = TVs && TVs.resultData && TVs.resultData.music && TVs.resultData.music
    const MusicId = TVs && TVs.resultData && TVs.resultData.music ? TVs.resultData.music.id : null
    let token = localStorage.getItem("token")
    const idtv = IDTVS
    const config = {
        onUploadProgress: progressEvent => {
            let percentCompleted = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
            setPercentage(percentCompleted)
            if (percentCompleted === 100) {
                notification["success"]({
                    message:
                        "The audio is Successfully uploaded !!",
                    description: "",
                })
            }
        }
    }
    const props = {
        name: 'file',
        accept: "audio/*",
        multiple: false,
        action: `https://api-hubersuhnercast.sane.agency/api/dashboard/tv-music/upload-music/${IDTVS}`,
        headers: {
            authorization: `Bearer ${token}`,
        },

        onChange(info) {
            const files = info && info.file ? info.file.originFileObj : null;
            const formData = new FormData();
            formData.append("files", files);

            if (info.file.percent === 100) {
                dispatch(UploadMusic({ idtv, formData }, config))

            }

        },
        beforeUpload: file => {
            const Validation = file.type === "audio/mp3" || file.type === "audio/mpeg" ? true : false
            return new Promise((resolve, reject) => {
                if (Validation === false) {
                    reject(null)
                    message.error('You can only upload audio file!');
                } else if (Validation === true) {
                    resolve(file)

                }
            })
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: percent => `${parseFloat(percent.toFixed(2))}%`,
        },
    };
    const audioList1 = [{
        name: `${MyMusic && MyMusic.musicName}`,
        musicSrc: `https://api-hubersuhnercast.sane.agency/${MyMusic && MyMusic.filePath}`,
    }]
    const options = {
        audioLists: audioList1,
        defaultPlayIndex: 0,
        theme: "dark",
        bounds: "body",
        clearPriorAudioLists: false,
        autoPlayInitLoadPlayList: false,
        preload: false,
        glassBg: false,
        remember: false,
        remove: false,
        defaultPosition: {
            top: 375,
            left: 338
        },
        defaultPlayMode: "order",
        mode: "mini",
        once: false,
        autoPlay: false,
        toggleMode: true,
        showMiniModeCover: true,
        showMiniProcessBar: false,
        drag: true,
        seeked: true,
        showDestroy: false,
        showProgressLoadBar: false,
        showPlay: true,
        showReload: false,
        showDownload: false,
        showPlayMode: false,
        showThemeSwitch: false,
        showLyric: false,
        extendsContent: [],
        defaultVolume: 100,
        playModeShowTime: 600,
        loadAudioErrorPlayNext: true,
        autoHiddenCover: true,
        // custom music player root node
        getContainer() {
            return document.body;
        },

    };
    // const [play, setPlay] = useState(false);
    const [playColor, setPlayColor] = useState(false);
    const [pauseColor, setPauseColor] = useState(false);
    const [audioInstance, setAudioInstance] = useState();

    const getAudioInstance = instance => {
        // console.log("Getting audio instance", instance);
        setAudioInstance(instance);
    };

    // const onPlayHandler = () => setPlay(true);
    // const onPauseHandler = () => setPlay(false);
    const OnClickPlay = () => {
        audioInstance.play();
        setPlayColor(true)
        setPauseColor(false)
        dispatch(PlayMusic(MusicId))
    }
    const OnClickPause = () => {
        audioInstance.pause();
        setPauseColor(true)
        setPlayColor(false)
        dispatch(PauseMusic(MusicId))
    }
    useEffect(() => {
        // console.log("Updated audio instance", audioInstance);
    }, [audioInstance, MyMusic, options.audioLists]);
    // console.log("MyMusic", MyMusic)

    return (
        <Row gutter={[24]} justify="space-between" align="left" style={{ marginLeft: "0.4em" }}>
            <Col span={24} md={24} style={{ margin: "2em 0", marginTop: "2em", paddingLeft:0 }} >
                <span className="Add-Music">Add Music</span>
            </Col>
            <Col span={24} md={24} className="upload-div-music" style={{paddingLeft:0}}  >
                <IconButton style={{ color: !playColor ? "#707070" : "#0B77C2", padding: 0 }}
                    onClick={OnClickPlay}>
                    <PlayCircleIcon />
                </IconButton>
                <IconButton style={{ color: !pauseColor ? "#707070" : "#0B77C2", padding: 0 }}
                    onClick={OnClickPause}>
                    <StopCircleIcon />
                </IconButton>
                {/* <img alt="example"
                    src={playBtn}
                    style={{ width: "9%", paddingRight: "0.5em" }}
                    onClick={() => {
                        audioInstance.play();
                    }}
                /> */}
                {/* <PlayCircleFilled />     */}
                {/* <img alt="example" src={pauseBTN}
                    style={{ width: "9%", paddingRight: "0.5em" }}
                    onClick={() => {
                        audioInstance.pause();
                    }} /> */}
                <div style={{}}>
                    <Upload {...props}
                        maxCount={1}
                    >
                        <Button className="btn-upload-music">
                            {/* { Music && audio && Music.Music ?
                            <span style={{ color: "rgb(11, 119, 194)" }}>{audio.name}</span>
                            :
                            <div> */}
                            {MyMusic && MyMusic.filePath ?
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between"}}>
                                    <span style={{
                                        color: "rgb(11, 119, 194)",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        width: "100%"
                                    }}>{MyMusic.musicName} </span>
                                    <UploadOutlined style={{color: "rgb(11, 119, 194)"}} />
                                </div>

                                : <span>Upload music ...</span>}
                            {/* </div>
                        } */}
                        </Button>
                    </Upload>
                    {Percentage > 0 ?
                        <Progress percent={Percentage} style={{ width: "60%", height: "60%", fontSize: 16 }} />
                        : null
                    }
                </div>


                {MyMusic && MyMusic.filePath ?
                    <ReactJkMusicPlayer
                        {...options}
                        getAudioInstance={getAudioInstance}
                    // onAudioPlay={onPlayHandler}
                    // onAudioPause={onPauseHandler}
                    />

                    : null}

                {/* <button
        type="button"
        onClick={() => {
          if (audioInstance)
            play ? audioInstance.pause() : audioInstance.play();
        }}
      >
        {play ? "Pause" : "Play"}
      </button> */}

            </Col>

        </Row>
    )
}

export default AddMusic