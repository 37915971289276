import React from 'react'
import { Card, Skeleton, Row, Col } from "antd";

function SkeletonCard() {
    const SkeletonList = [1, 2, 3, 4, 5, 6, 7, 8, 9]

    return (
        <Row gutter={[24, 0]} >
            {SkeletonList.map((item, index) => (
                <Col span={12} xs={24} sm={12} md={12} lg={8} xl={8} xxl={8} className="mb-24" key={index}>

                    <Card
                        key={item.id}
                        bordered={false}
                        // style={{ width: 300 }}
                    >
                        <Skeleton.Input active size="default" />
                        <Skeleton.Image style={{ height: "24vh", width: "50vh", marginTop: "1em" }} />

                    </Card>
                </Col>
            ))}
        </Row>
    )
}

export default SkeletonCard