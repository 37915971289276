import React, { useEffect, useState } from 'react'
import { Button, Row, Col, Upload, Input, Radio, notification, Spin, message, Progress } from "antd";
import AddTicker from './AddTicker';
import { AddDocument } from "../../../Redux/actions/DocumentAction"
import { useDispatch, useSelector } from 'react-redux'
import { TimePicker } from 'antd';
import { UploadDocument } from "../../../Redux/actions/DocumentAction"
import { LoadingOutlined } from '@ant-design/icons';
// import LoadingOverlay from 'react-loading-overlay';
// import { LoadingOverlay, Loader } from 'react-overlay-loader';
// import 'react-overlay-loader/styles.css';

import moment from 'moment';
import Spinner from '../../Spinner/Spinner';

function AddMedia({ TVId, TVsSelected, checked, onChange, ListAnimation }) {
    const [radioItem, setRadioItem] = useState(0);
    // const [NumberItem, setNumberItem] = useState([]);
    const [AnimationItem, setAnimationItem] = useState([]);
    const [TimeItem, setTimeItem] = useState("");
    const [recurrentItem, setRecurrentItem] = useState();
    const [loader, setLoader] = useState(false)
    const [percent, setPercent] = useState(0)
    const [fileConfirm, setfileConfirm] = useState(false)
    const [uploadPercentage, setUploadPercentage] = useState();
    const [result, setResult] = useState(false)
    const filePathDoc = JSON.parse(localStorage.getItem("filePdf"))
    const isDoc = useSelector(state => state.Doc.isDoc)
console.log("isDoc", isDoc)

// const loading = useSelector(state => state.Doc.loader)
    // const [, set] = useState(second)
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const format = 'HH:mm:ss';
    const RadioList = [
        "Album", "Image", "Video", "PDF", "Excel/PowerPoint", "Camera"
    ]
    const dispatch = useDispatch()
    // const Doc = useSelector(state => state.Doc)
    const onChangeRadio = e => {
        setRadioItem(e.target.value)
        localStorage.removeItem('filePdf')
        setAnimationItem([])

    };
    const onClickRadio = () => {
        setTimeItem("00:00:01")
        setRecurrentItem()
        setAnimationItem([])
    }
    const OnChangeAnimation = (e) => {
        setAnimationItem(e.target.name)
    }
    let token = localStorage.getItem("token")
    const config = {
        onUploadProgress: progressEvent => {
            let percentCompleted = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
            setUploadPercentage(percentCompleted)
            if (percentCompleted === 100) {
                notification["success"]({
                    message:
                        "The video is Successfully uploaded !!",
                    description: "",
                })
            }
        }
    }

    const props = {
        name: 'file',
        action: `https://api-hubersuhnercast.sane.agency/api/dashboard/tv-document/upload-document-medias`,
        headers: {
            authorization: `Bearer ${token}`,
        },

        beforeUpload: (file) => {
            if (radioItem === 1) {
                const Validation = file.type === "image/jpeg" ||  file.type === "image/svg" || file.type === "image/png" ? true : false
                return new Promise((resolve, reject) => {
                    if (Validation === false) {
                        reject(null)
                        setfileConfirm(false);
                        message.error('You can only upload Image file!');
                    } else if(Validation === true){ 
                        resolve(file)
                        setfileConfirm(true);
                    }
                })
            }
            if (radioItem === 2) {
                const Validation = file.type === "video/mp4" ? true : false
                return new Promise((resolve, reject) => {
                    if (Validation === false) {
                        reject(null)
                        setfileConfirm(false);

                        message.error('You can only upload Video file!');
                    } else if(Validation === true){
                         resolve(file)
                         setfileConfirm(true);}
                })
            }

            if (radioItem === 3) {
                const Validation = file.type === "application/pdf" ? true : false
                return new Promise((resolve, reject) => {
                    if (Validation === false) {
                        reject(null)
                        setfileConfirm(false);
                        message.error('You can only upload pdf file!');
                    } else if(Validation === true){
                        resolve(file)
                        setfileConfirm(true);
                    }
                })
            }
            if (radioItem === 4) {
                const Validation = file.type === "application/vnd.ms-excel" || file.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation" || file.type ==="application/vnd.ms-powerpoint" 
                || file.type ==="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? true : false
                return new Promise((resolve, reject) => {
                    if (Validation === false)  {
                        reject(null)
                        setfileConfirm(false);
                        message.error('You can only upload Excel and PowerPoint file!');
                    } else if(Validation === true) {
                        resolve(file)
                        setfileConfirm(true);

                    }
                })
            }

        },
        onChange(info) {
            const files = info && info.file ? info.file.originFileObj : null;
            const formData = new FormData();
            formData.append("files", files);
            if (info.file.percent === 100) {
                if (radioItem === 2) {
                    dispatch(UploadDocument(formData, config))

                }
                else
                    dispatch(UploadDocument(formData))
                // notification["success"]({
                //     message:
                //         "Success !!",
                //     description: "",
                // })

            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }

        },
            progress: {
                strokeColor: {
                    '0%': '#108ee9',
                    '100%': '#87d068',
                },
                strokeWidth: 3,
                format: percent =>  `${parseFloat(percent.toFixed(2))}%` ,
            } ,
    };
    // const dummyRequest = ({ file, onSuccess }) => {
    //     setTimeout(() => {
    //         onSuccess("ok");
    //     }, 0);
    // };
    const propsAlbum = {
        name: 'file',
        // customRequest: dummyRequest,

        action: `https://api-hubersuhnercast.sane.agency/api/dashboard/tv-document/upload-document-medias`,
        headers: {
            authorization: `Bearer ${token}`,
        },
    beforeUpload: (file) => {
            if (radioItem === 1) {
                const Validation = file.type === "image/jpeg" ||  file.type === "image/svg" || file.type === "image/png" ? true : false
                return new Promise((resolve, reject) => {
                    if (Validation === false) {
                        reject(null)
                        setfileConfirm(false);
                        message.error('You can only upload Image file!');
                    } else if(Validation === true){ 
                        resolve(file)
                        setfileConfirm(true);
                    }
                })
            }
            if (radioItem === 2) {
                const Validation = file.type === "video/mp4" ? true : false
                return new Promise((resolve, reject) => {
                    if (Validation === false) {
                        reject(null)
                        setfileConfirm(false);

                        message.error('You can only upload Video file!');
                    } else if(Validation === true){
                         resolve(file)
                         setfileConfirm(true);}
                })
            }

            if (radioItem === 3) {
                const Validation = file.type === "application/pdf" ? true : false
                return new Promise((resolve, reject) => {
                    if (Validation === false) {
                        reject(null)
                        setfileConfirm(false);
                        message.error('You can only upload pdf file!');
                    } else if(Validation === true){
                        resolve(file)
                        setfileConfirm(true);
                    }
                })
            }
            if (radioItem === 4) {
                const Validation = file.type === "application/vnd.ms-excel" || file.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation" || file.type ==="application/vnd.ms-powerpoint" 
                || file.type ==="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? true : false
                return new Promise((resolve, reject) => {
                    if (Validation === false)  {
                        reject(null)
                        setfileConfirm(false);
                        message.error('You can only upload Excel and PowerPoint file!');
                    } else if(Validation === true) {
                        resolve(file)
                        setfileConfirm(true);

                    }
                })
            }

        },
        onChange(info) {

            const files = info && info.fileList ? info.fileList.map(e => e.originFileObj) : null;
            const percents = info && info.fileList ? info.fileList.map(e => e) : null;
        
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append("files", files[i])
                setPercent(files[i])

            }
            for (let i = 0; i < percents.length; i++) {
                setPercent(percents[i].percent)

            }
        
     
            if (percent && percent === 100) {
                dispatch(UploadDocument(formData))
               
                if (radioItem === 0) {
                    const arrayValid = percents.map(e => e.type.includes("image/jpeg") 
                    || e.type.includes("image/svg") ||  e.type.includes("image/png") 
                    || e.type.includes("video/mp4") ||  e.type.includes("application/pdf") 
                    || e.type.includes("application/vnd.ms-excel") 
                    || e.type.includes("application/vnd.openxmlformats-officedocument.presentationml.presentation") 
                    || e.type.includes("application/vnd.ms-powerpoint") 
                    || e.type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") 
                   )

                    const Validation = arrayValid.includes(false) ? false : true
                    return new Promise((resolve, reject) => {
                        if (Validation === false) {
                            reject(null)
                            setfileConfirm(false)
                            message.error(`You can not upload ${info.file.type} type file!`);
                        } else if (Validation === true) {
                                resolve(percents)
                                setfileConfirm(true);
                                // message.success(`the files uploaded successfully!`);

                        }
                    })
                }

            }
            // if(fileConfirm === true ){
            //     message.success(`the files uploaded successfully!`);

            // }

            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },

        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: percent => percent && `${parseFloat(percent.toFixed(2))}%`,
        },
    };
    // console.log("fileConfirm", fileConfirm)
    function onChangeTime(time, timeString) {
        setTimeItem(timeString)
    }
    function onChangerecurrent(time, timeString) {
        setRecurrentItem(timeString)
    }
    const SelectedTv = JSON.parse(localStorage.getItem("DocSelected"))
    const TvsListId = []
    if (SelectedTv) {
        SelectedTv.map((ex, i) => {
            TvsListId.push(ex.id)
        })
    }
    const AddMediaDoc = (e) => {
        setLoader(true)
        e.preventDefault();
        const medias = filePathDoc ? filePathDoc : null
        const tvId = window.location.pathname === "/dashboard/add-NewTv" || window.location.pathname === "/dashboard/Edit-Tv" ? [TVId] : TvsListId
        const data = {
            displayOrder: 0,
            // startTime: "00:00:00",
            displayTime: TimeItem,
            recurent: recurrentItem,
            startAnimation: (parseInt(AnimationItem)),
            endAnimation: (parseInt(AnimationItem)),
            type: radioItem,
            medias,
            tvId
        }

        if (fileConfirm === true) {
                if ( radioItem === 0  ) {
                    if (data.recurent !== undefined && data.displayTime > data.recurent) {
                        dispatch(AddDocument(data))
                        setLoader(false)

                        window.scroll({
                            top: 0, 
                            left: 0, 
                            behavior: 'smooth' 
                           });                 
                        // setResult(true)
                    }
                    else
                        notification["error"]({
                            message:
                                "The Display Time should be more then the Recurrent time So please try again !!",
                            description: "",
                        })
                }
                else if(data.recurent === undefined || radioItem !== 0){
                    dispatch(AddDocument(data))
                    setLoader(false)
                    // setResult(true)

                    setRecurrentItem()
                    window.scroll({
                        top: 0, 
                        left: 0, 
                        behavior: 'smooth' 
                       });
                }     
                setLoader(false)
 
        }
        else
            notification["error"]({
                message:
                    "Please Check the type of your file !!",
                description: "",
            })
            setLoader(false)
            // setResult(true)

        
       

    }
    useEffect(() => {
    }, [radioItem])
    // useEffect(() => {
    //     if(result === true  && isDoc === null){
    //         setTimeout(setLoader, 10, true)
    //         // setLoader(false)
    //      }
    //      else if (isDoc !== null){
    //      setLoader(false)

    //      }
    // }, [result])
console.log("loader", loader)
console.log("result", result)
    return (
        <div className="add-container-media-div" style={{ padding: "10em 0", lineHeight: 5 }}>
            {loader && <Spinner>LOADING...</Spinner>}
            <Row gutter={[24]} justify="space-between" align="left">
                <Col span={24} md={24} className="ml-auto">
                    <h1 className="title-addMedia">Add Media / Document</h1>
                </Col>
                <Col span={24} md={24} className="ml-auto add--switchers-list" >
                    <Radio.Group onChange={onChangeRadio} onClick={onClickRadio} value={radioItem}>
                        {RadioList.map((item, index) => (
                            <Radio key={index} value={index}>{item}</Radio>
                        ))}

                    </Radio.Group>
                </Col>
                {radioItem !== 5 ?
                    <div
                    >
                        <Col span={24} md={24} className="ml-auto uploadMedia-music" >
                            {radioItem === 0 &&
                                <Upload {...propsAlbum}
                                    accept="image/jpeg, image/svg, image/png, video/mp4, application/pdf, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xml,.xls,.xlsx .pptx, .ppa, .ppam, .ppt,  .csv"
                                    multiple

                                >
                                    <Button className="btn-upload-file">Select file ...</Button>
                                </Upload>
                            }

                            {radioItem === 1 &&
                                <Upload {...props}
                                    accept="image/jpeg, image/svg, image/png"
                                    maxCount={1}
                                >
                                    <Button className="btn-upload-file" >Select file ...</Button>
                                </Upload>

                            }
                            {radioItem === 2 &&
                                <div>
                                    <Upload {...props}
                                        accept="video/mp4"
                                        maxCount={1}
                                    >
                                        <Button className="btn-upload-file" >Select file ...</Button>
                                    </Upload>
                                    {uploadPercentage > 0 ?
                                        <Progress percent={uploadPercentage} style={{ width: "60%", height: "60%", fontSize: 16 }} />
                                        : null
                                    }
                                </div>
                            }
                            {radioItem === 3 &&
                                <Upload {...props}
                                    accept="application/pdf"
                                    maxCount={1}
                                >
                                    <Button className="btn-upload-file" >Select file ...</Button>
                                </Upload>

                            }
                            {radioItem === 4 &&
                                <Upload {...props}
                                    accept="application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xml,.xls,.xlsx .pptx, .ppa, .ppam, .ppt, .csv"
                                    maxCount={1}
                                >
                                    <Button className="btn-upload-file" >Select file ...</Button>
                                </Upload>

                            }
                        </Col>
                        {radioItem === 0 ?

                            <Col span={24} md={24} className="ml-auto" >
                                <span className="title-sub-addmedia">Recurrent</span>
                                <TimePicker
                                 defaultOpenValue={moment('00:00:01', format)}
                                 format={format} onChange={onChangerecurrent} 
                                 disabledDate={d => !d || d.isBefore(moment('00:00:01', format))  }                       
                                 />
                            </Col>
                            : null}
                        <Col span={24} md={24} className="ml-auto" >
                            <span className="title-sub-addmedia">Display Time</span>
                            <TimePicker 
                            defaultOpenValue={moment('00:00:01', format)}
                             format={format}
                              onChange={onChangeTime}
                              disabledDate={d => !d || d.isBefore(moment('00:00:01', format))  }                               />
                        </Col>

                        <Col span={24} md={24} className="ml-auto" >
                            <Row gutter={[24]} justify="space-between" align="left"
                                style={{ marginTop: "2em", marginBottom: "1em", alignItems: "flex-end" }} >
                                <Col span={24} md={16} className="ml-auto"
                                    style={{ display: "flex", flexDirection: "column" }} >
                                    <span className="title-sub-addmedia">Animation Change</span>
                                    <div >
                                        {ListAnimation.map((e, index) => (
                                            <Button name={index} onClick={OnChangeAnimation}><img src={e} name={index} alt="Animation" className='Animation-button' /></Button>
                                        ))}
                                    </div>
                                </Col>
                                <Col span={24} md={8} className="ml-auto addmedia-container-btn" style={{ textAlign: "right" }} >
                                    <Button style={{ height: "5vh" }} onClick={AddMediaDoc} >
                                        {!loader ?
                                            <span> Submit</span>
                                            :
                                            <Spin indicator={antIcon} />
                                        }
                                    </Button>
                                </Col>
                            </Row>
                        </Col>

                    </div>
                    :
                    <div>
                        <Col span={24} md={24} className="ml-auto" style={{ marginBottom: "1em" }}>
                            <Input placeholder="IP address / Link" style={{ height: "6vh", borderRadius: 5, width: "64vh" }} />

                        </Col>
                        <Col span={24} md={24} className="ml-auto" style={{ marginBottom: "1em" }}>
                            <Input placeholder="TV Name" style={{ height: "6vh", borderRadius: 5, width: "64vh" }} />

                        </Col>
                        <Col span={24} md={24} className="ml-auto" style={{ marginBottom: "1em" }} >
                            <Input placeholder="Password" style={{ height: "6vh", borderRadius: 5, width: "64vh" }} />

                        </Col>
                        <Col span={24} md={24} className="ml-auto addmedia-container-btn" style={{ marginBottom: "1em", textAlign: "end" }} >

                            <Button style={{ height: "5vh" }}  >

                                Submit

                            </Button>
                        </Col>
                    </div>}
                    {/* <Loader loading={loader}/> */}

            </Row>
            <AddTicker
                TVsSelected={TVsSelected}
                TVId={TVId}
                checked={checked}
                onChange={onChange}

            />
        </div>
    )
}

export default AddMedia