import React, { useEffect, useState } from 'react'
import imageTvPlay from "../../Assets/Images/play-button.svg"
import { Card, Col, Row, Pagination, Image } from "antd";
import { useDispatch, useSelector } from 'react-redux'
import { getAllTvs } from "../../Redux/actions/TvsAction"
import "./style.css"
import Topbar from '../../layout/Topbar';
import SkeletonCard from '../Skeleton/SkeletonCard';
import NotFound from "../../Assets/Images/Search engines-bro.svg"

function Home() {
  const Tvs = useSelector(state => state.Tvs.Tvs)
  const [TvsList, setTvsList] = useState([])
  const [minIndex, setMinIndex] = useState(0)
  const [maxIndex, setMaxIndex] = useState(15)
  const [search, setSearch] = useState("");

  const numEachPage = 15
  const dispatch = useDispatch()
  const handleChange = (page) => {
    setMinIndex((page - 1) * numEachPage)
    setMaxIndex(page * numEachPage)
  }

  useEffect(() => {
     
    dispatch(getAllTvs())

  }, [])
  useEffect(() => {
    if (Tvs) {
      const TvsResult = Tvs && Tvs.resultData && Tvs.resultData.results
      setTvsList(TvsResult)
    }
  }, [Tvs])
  return (
    <div>
      <Topbar 
      search ={search}
      setSearch={setSearch}
      TvsList={TvsList}
        Tvs={Tvs}
        setTvsList={setTvsList} />
      {/* <div className="grid-item-watchtv"> */}
      <Row gutter={[24, 0]} className={TvsList && !TvsList.length > 0 ? "flex-not-found": "grid-item-watchtv" } >
      {TvsList ? (
         TvsList.length > 0 ?
     TvsList.slice(minIndex, maxIndex).map((item, index) => (
          <Col span={24} xs={24} sm={12} md={12} lg={12} xl={8} xxl={8} key={index} style={{ marginBottom: "1em" }}>

            <Card
              title={item.name}
              key={item.id}
              bordered={false}
            // style={{ width: 300 }}
            >
               <Image
                        src={`https://api-hubersuhnercast.sane.agency/${item.displayedImage}`}
                        style={{
                          maxWidth: "100%",
                          //  height: "auto",
                          height: "30vh",
                          borderRadius:"10px",
                          objectFit: "cover"
                        }}
                      />
             
            
            </Card>

          </Col>
        ))
        : <div style={{   
          display: "flex",
         flexDirection: "column",
         alignItems: "center"}}> 
         <img src={NotFound} alt ="not found" style={{width: "100%", marginTop:"4em"}}/>
         <span className="Not-found-text">No exact matches found</span></div>
      )
          :
          <SkeletonCard />
        }
      </Row>
      <Pagination
        defaultCurrent={1}
        defaultPageSize={numEachPage} //default size of page
        onChange={handleChange}
        total={TvsList && TvsList.length} //total number of card data available
        style={{ bottom: "0px", textAlign: "center",  marginTop: "6em"  }}
      />
      {/* </div> */}
    </div >
  )
}

export default Home