import React from 'react'
import RouteMain from './RouteMain'
import { Layout } from 'antd';
import Sidebar from '../layout/Sidebar';

// import Topbar from '../layout/Topbar';
const { Header, Sider, Content } = Layout;

function Main() {
    // const [collapsed, setCollapsed] = useState(false)
    // const toggle = () => {
    //     setCollapsed(!collapsed);
    //   };
    // console.log("history", window.location.push("./"))
// useEffect(() => {
//     axios.interceptors.response.use(response => {
//         return response;
//      }, error => {
//        if (error.response.status === 401) {
//         window.location.push("./")
//        }
//        return error;
//      });
// }, [])

    return (

        <Layout>
            <Sider trigger={null} collapsible
            //  collapsed={collapsed}
            >

                <Sidebar />
            </Sider>

            <Layout className="site-layout" >
                <Header className="site-layout-background" style={{ padding: 0 }}>
                    <div className='trigger'>
                        {/* <Topbar /> */}
                        {/* {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
           {
             onClick: toggle,
           })}  */}
                    </div>

                </Header>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                    }}>
                    <RouteMain />
                </Content>
            </Layout>
        </Layout>
    )
}

export default Main