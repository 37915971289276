import React, { useState, useEffect } from 'react'
import { Row, Col } from "antd";
import videoImg from "../../Assets/Images/video.svg"
// import animation1 from "../../Assets/icons/fast-forward1.svg"
// import animation2 from "../../Assets/icons/fast-forward2.svg"
// import animation3 from "../../Assets/icons/fast-forward3.svg"
// import animation4 from "../../Assets/icons/fast-forward4.svg"
// import animation5 from "../../Assets/icons/blur1.svg"
// import animation6 from "../../Assets/icons/blur2.svg"
// import AddMedia from './addContent/AddMedia';
import RegisterTv from './RegisterTv';
import { GetDocument, DeleteDocument } from "../../Redux/actions/DocumentAction"
import { useDispatch, useSelector } from 'react-redux'
// import AddMusic from './addContent/AddMusic';
import { GetActive, getTvById } from "../../Redux/actions/TvsAction"
// import DocumentsList from './containers/DocumentsList';

function AddTv() {
    // const [checked, setChecked] = useState(false)
    // const [switcher, setSwitcher] = useState(false)
    const dispatch = useDispatch()
    const TVId = useSelector(state => state.Auth.TvId)
    const TVs = useSelector(state => state.Tvs.TvId)
    // console.log("TVs****", TVs)
    // const ListAnimation = [
    //     animation1, animation2, animation3, animation4, animation5, animation6
    // ]
    const Document = useSelector(state => state.Doc.Document)
    // const onChange = e => {
    //     setChecked(
    //         e.target.checked,
    //     );
    // };

    // function onChangeSwitch(checked) {
    //     setSwitcher(checked)

    // }
    // const OnClickSwitch = () => {
    //     if (TVId) {
    //         dispatch(GetActive(TVId, switcher ))

    //     }
    // }
    
    // const SelectedTv = JSON.parse(localStorage.getItem("DocSelected"))


    const DOCList = []
    if (Document) {
        Document.map((ex, i) => {
            DOCList.push({
                key: (i + 1).toString(),
                keyPath: i.toString(),
                name: `${ex.displayName}`,
                id: `${ex.id}`

            })
        })
    }
    // const DeleteDoc = (event) => {
    //     dispatch(DeleteDocument(event.target.id))
    // }
    useEffect(() => {
        if (TVId) {
            dispatch(GetDocument(TVId))
            dispatch(getTvById(TVId))

        }

    }, [TVId])
    useEffect(() => {
        if (window.location.pathname === "/dashboard/add-NewTv") {
            localStorage.removeItem('DocSelected')

        }
    }, [])


    return (
        <div>
            <h4 className="TV-Huber-name" style={{color: "#0B77C2"}}>Add New TV</h4>

            <Row gutter={[40, 0]} justify="space-between" align="middel"
            //  style={{display:"flex",justifyContent: "center"}}
              >

                <Col span={24} md={9} style={{ display: "flex", flexDirection: "column" }} >
                    {/* <Row gutter={[24]} justify="space-between" align="middel" > */}

                        {/* <Col span={24} md={24}
                        //  style={{ padding: 0,  marginTop: TVs ?  "-3.2em" : "-1.4em" }} 
                         > */}
                            {/* <h4 className="TV-Huber-name">{TVs && TVs.resultData && TVs.resultData.name}</h4> */}
                            <img alt="example" src={videoImg} style={{maxWidth:"100%" ,height: "auto"}} />
                        {/* </Col> */}
                        {/* <AddMusic IDTVS={TVId && TVId} /> */}
                    {/* </Row> */}
                </Col>
                <Col span={24} md={15}  >
                    <RegisterTv TVs={TVs} />
                    {/* <DocumentsList
                       TVs={TVs}
                        SelectedTv={SelectedTv && SelectedTv}
                        switcher={switcher}
                        OnClickSwitch={OnClickSwitch}
                        onChangeSwitch={onChangeSwitch}
                        DOCList={!SelectedTv !== null ? DOCList : []}
                        DeleteDoc={DeleteDoc}
                        Document={Document} /> */}
                </Col>
            </Row>
            {/* // /* <AddMedia
            //     TVId={TVId}
            //     checked={checked}
            //     onChange={onChange}
            //     ListAnimation={ListAnimation}
            // />*/}
        </div> 
    )
}

export default AddTv