import React, { useState, useEffect } from 'react'
import { Button, Col, Input, Checkbox, Radio, Row, Spin, notification } from "antd";
import { AddTickers } from "../../../Redux/actions/TickerAction"
import { useDispatch, useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons';
// import { getAllTvs } from '../../../Redux/actions/TvsAction';

const { TextArea } = Input;

function AddTicker({ TVId, TVsSelected }) {
    const dispatch = useDispatch()
    const [Value, setValue] = useState("")
    const [radio, setRadio] = useState([]);
    const [checked, setChecked] = useState(false)
    const [loader, setLoader] = useState(false)
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const Ticker = useSelector(state => state.Ticker)
    // const AllTvs = useSelector(state => state.Tvs.Tvs)

  console.log("AllTvs", TVId )
    // console.log("Ticker", Ticker)
    function onChangechecked(e) {
        setChecked(e.target.checked)
    }
    const onChangeRadio = e => {
        setRadio(e.target.value)
    };
    const SelectedTv = JSON.parse(localStorage.getItem("DocSelected"))
    const TvsListId = []
    if (SelectedTv) {
        SelectedTv.map((ex, i) => {
            TvsListId.push(ex.id)
        })
    }
    const OnchangeDiscription = (e) => {
        // setValue({ ...Value, [e.target.name]: e.target.value })

        setValue(e.target.value)
    }
    const OnClickTicker = () => {
        setLoader(true)

        const data = {
            description: Value,
            type: radio,
            ispublished: checked,
            tvIds: window.location.pathname === "/dashboard/add-NewTv" || window.location.pathname === "/dashboard/Edit-Tv" ? [TVId] : TvsListId
        }
        dispatch(AddTickers(data))
    }
    const RadioList = ["Alert", "News"]
    useEffect(() => {
        if (Ticker.isTicker) {
            setLoader(false)
        }
        else 
        setLoader(false)

    }, [Ticker, TVsSelected])
//     useEffect(() => {
//   dispatch(getAllTvs())
//     }, [])
    
    console.log(".resultData", TVsSelected)
    return (
        <Row gutter={[24]} justify="space-between" align="left">
            <Col span={24} md={24} className="ml-auto" >
                <span className="title-addMedia">New Ticker</span>
                <Checkbox
                    checked={checked}
                    onChange={onChangechecked}
                />
            </Col>
            <Col span={24} md={24} className="ml-auto addmedia-container-btn"
                style={{ display: "flex", flexDirection: "column" }} >
                <TextArea rows={10}
                    defaultValue={TVsSelected && TVsSelected.ticker.description }
                    value={!Value ? TVsSelected && TVsSelected.ticker.description : Value }
                    placeholder="Paragraph ..."
                    style={{ width: "58%" }}
                    name='description'
                    onChange={OnchangeDiscription}
                />
                <div style={{ textAlign: "right", width: "58%" }}>
                    <Radio.Group onChange={onChangeRadio} value={radio}>
                        {RadioList.map((item, index) => (
                            <Radio key={index} value={index}>{item}</Radio>
                        ))}
                    </Radio.Group>
                    <Button style={{ height: "57%" }} onClick={OnClickTicker}>
                        {!loader ?
                            <span> Submit</span>
                            :
                            <Spin indicator={antIcon} />
                        }
                    </Button>
                </div>

            </Col>
        </Row>
    )
}

export default AddTicker