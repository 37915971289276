import './App.css';
import { Switch, Route } from "react-router-dom";
import Login from "./components/login/index"
import 'antd/dist/antd.css';
import Main from './routers/Main';

function App() {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={Login} />
        {/* <Route path="*" component={Login} /> */}
        <Main />
      </Switch>

    </div>
  );
}

export default App;
