import { combineReducers } from 'redux'
import AuthReducer from "./AuthReducer";
import DocumentReducer from './DocumentReducer';
import MusicReducer from './MusicReducer';
import TickerReducer from './TickerReducer';
import TvsReducer from './TvsReducer';

export default combineReducers({
  Auth: AuthReducer, 
  Tvs: TvsReducer,
  Doc : DocumentReducer,
  Ticker: TickerReducer,
  Music: MusicReducer
}); 
