import React,{useEffect} from 'react'
import { InputAdornment, Input, FormControl } from '@mui/material'
import SearchIcon from "../Assets/icons/searchIcon.svg"
import CloseIcon from '@mui/icons-material/Close';
function Topbar({ TvsList, setTvsList, Tvs , search, setSearch }) {

    const handleChangeSearch = (event) => {
        setSearch(event.target.value)
    }

    const SearchData = () => {
        if (TvsList) {
            let ChapterFiltred = []
            if (search !== "") {
                if (TvsList) {
                    TvsList.map(e => e.name.toUpperCase().includes(search.toUpperCase()) ? ChapterFiltred.push(e) : true)
                }
            }
            setTvsList(ChapterFiltred)
        }
    }

    const handleKeyPress = (e) => {
        // e.preventdefault()
        if (e.key === 'Enter') {
            
            SearchData()
        }
        if(!search){
            setSearch("")
            setTvsList(TvsResult)
        }
    }
    const TvsResult = Tvs && Tvs.resultData && Tvs.resultData.results
    const allowClear = () => {
        setSearch("")
        setTvsList(TvsResult)

    }
 
    
    return (
        <div className="flex-top-bar" >
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <Input
                    onChange={handleChangeSearch}
                    onKeyPress={handleKeyPress}
                    placeholder='Search TV…'
                    id="standard-adornment-amount"
                    value={search}
                    endAdornment={
                        // search ?
                        <div style={{ cursor: "pointer" }} onClick={allowClear}> <CloseIcon style={{ fontSize: "0.9em" }} /></div>
                        // : null
                    }

                    sx={{ m: 1, width: '35ch' }}
                    startAdornment={<InputAdornment position="start">
                        <img src={SearchIcon} alt="SearchIcon" style={{ width: "64%" }} />
                    </InputAdornment>}
                    variant="filled"
                />
            </FormControl>
            {/* <div>
                <img src={avatar} alt="SearchIcon" style={{ width: "64%" }} />
            </div> */}

        </div>
    )
}

export default Topbar