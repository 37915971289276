import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGIN_TV_SUCCESS,
    LOGIN_TV_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL
} from '../actions/type'
let INIT_STATE = {
    token: null,
    isAuth: false,
    errors: null,
    TvAuth: false,
    TvId: null,
    tokenTv: localStorage.getItem("tokenTv")
}
const UserReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            localStorage.setItem('token', action.payload.accessToken)
            localStorage.setItem('isAuth', true)

            return {
                ...state,
                token: action.payload.accessToken,
                isAuth: true,
                errors: null
            };
        case LOGIN_FAIL:
            localStorage.removeItem('token')
            localStorage.removeItem('isAuth')
            return {
                ...state,
                isAuth: false,
                errors: action.payload.data
            };
            case LOGIN_TV_SUCCESS:
                localStorage.setItem('tokenTv', action.payload.accessToken)
                localStorage.setItem('TvAuth', true)
                // console.log("action.payload", action.payload)
                return {
                    ...state,
                    tokenTv: action.payload.accessToken,
                    TvAuth: true,
                    isAuth: true,
                    TvId: action.payload.tvId,
                    errors: null
                };
            case LOGIN_TV_FAIL:
                localStorage.removeItem('tokenTv')
                localStorage.removeItem('TvAuth')
                return {
                    ...state,
                    TvAuth: false,
                    TvId: null,
                    errors: action.payload
                };
                case LOGOUT_SUCCESS:
                    localStorage.removeItem('token')
                    localStorage.removeItem('isAuth')
        
                    return {
                        ...state,
                        isAuth: false,
                        errors: null
                    };
                case LOGOUT_FAIL:
                    localStorage.removeItem('token')
                    localStorage.removeItem('isAuth')
                    return {
                        ...state,
                        isAuth: false,
                        errors: action.payload.data
                    };
        default:
            return state
    }
}
export default UserReducer