import {
    LOAD_TVS_SUCCESS,
    LOAD_TVS_FAIL,
    SELECTED_TVS_SUCCESS,
    SELECTED_TVS_FAIL,
    GET_TV_SUCCESS,
    GET_TV_FAIL,
    ACTIVE_TVS_SUCCESS,
    ACTIVE_TVS_FAIL,
    EDIT_TVSNAME_SUCCESS,
    EDIT_TVSNAME_FAIL
} from './type'
import axios from 'axios'
import {URL_LOGIN, URL_TV} from "../../utils/GlobalURL"
import { notification } from "antd";

import SetToken from '../../utils/SetToken' 
export const getAllTvs = () => (dispatch) => {
    SetToken()
    axios.get(`${URL_TV}/tvs/all-tvs/1/500`)
        .then(res => dispatch({
            type: LOAD_TVS_SUCCESS,
            payload: res.data

        }))
        .catch(err =>
         {
            if (err.response.status === 401) {
                window.location = "/"
           }
           return err
           ,

         dispatch({
            type: LOAD_TVS_FAIL,
            payload: err.response
        })
    })

}
export const getSelectedTvs = (Ids) => (dispatch) => {
    SetToken()
    axios.get(`${URL_TV}/tvs/get-Selected-tvs?ids=${Ids}`)
        .then(res => dispatch({
            type: SELECTED_TVS_SUCCESS,
            payload: res.data

        }))
        .catch(err =>{
            if (err.response.status === 401) {
                window.location = "/"
           }
           return err,

           dispatch({
            type: SELECTED_TVS_FAIL,
            payload: err.response
        })
        })

}
export const getTvById = (Id) => (dispatch) => {
    SetToken()
    axios.get(`${URL_TV}/tvs/tv/${Id}`, {
        // cancelToken: source.token
      })
        .then(res => dispatch({
            type: GET_TV_SUCCESS,
            payload: res.data

        }))
        .catch(err =>{
            if (err.response.status === 401) {
                window.location = "/"
           }
           return err,
           dispatch({
            type: GET_TV_FAIL,
            payload: err.response
        })
        } )

}
export const GetActive = (tvId, isActive) => (dispatch) => {
    // console.log("GetActive",tvId, !isActive )
    SetToken()
    axios.put(`${URL_TV}/tvs/tv-Active/${tvId}/${!isActive}`)
        .then(res => dispatch({
            type: ACTIVE_TVS_SUCCESS,
            payload: res.data
        }))
        .catch(err => {
            if (err.response.status === 401) {
                window.location = "/"
           }
           return err,
            dispatch({
            type: ACTIVE_TVS_FAIL,
            payload: err.response
        })
    })

}
export const EditName = (tvId, data) => (dispatch) => {
    // console.log("GetActive",tvId, !isActive )
    SetToken()
    axios.put(`${URL_LOGIN}/edit/${tvId}`, data )
    .then(res =>{
        // console.log("res", res)
                    dispatch({
                        type: EDIT_TVSNAME_SUCCESS,
                        payload: res.data
            
                    })
                    notification["success"]({
                        message:
                            "Success !!",
                        description: "",
                    })
                } )
        
        .catch(err =>
            {    if (err.response.status === 401) {
                window.location = "/"
           }
           return err,
                dispatch({
                type: EDIT_TVSNAME_FAIL,
                payload: err.response
            }),
            notification['error']({
                message: 'your update is Failed !!',
               
              })
        
        })
          

}


