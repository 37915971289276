import React, { useState, useEffect } from 'react'
import img from "../../Assets/Images/Huber-Cover.svg"
import { Input, Button, Row, Col, Spin, notification, Form } from 'antd';
import { LoginAdmin } from '../../Redux/actions/Auth'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons';
import logo from "../../Assets/icons/logoHuber.svg"

import "./style.css"

function Login({ history }) {
  const [info, setInfo] = useState(
    {
      username: "",
      password: ""
    }
  )

  const [loader, setLoader] = useState(false)
  const handleChange = (e) => {
    setInfo({ ...info, [e.target.name]: e.target.value })
  }
  const dispatch = useDispatch()
  const auth = useSelector(state => state.Auth)
  const onFinish = () => {
    setLoader(true)
    
    dispatch(LoginAdmin(info))  
  };
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  useEffect(() => {
    if (auth && auth.isAuth) {
      history.push("./dashboard")
    }
    else 
    setLoader(false)
  }, [auth])
  return (
    <div className="log-In-container" >
      <img src={img} alt="pubLearning" className="img-login-background" />
      {/* <h1 >HUBER+SUHNER</h1> */}
      <img src={logo} alt="" style={{width:"30%"}} className='Huber-title-login'/>
      <div className="card-singIn">
        <Row gutter={[24, 0]} className="Row-log-in">
          <Col span={24} md={24} >
            <h5 className="Start-here">Sign in here!</h5>
          </Col>
          <Col span={24} md={24} >

            <Form className="form-log-in-container" >

              <Col span={24} md={24} className="input-login"  >
                <span className="span-title-input">Username</span>
                <Form.Item name="userName" rules={[{ required: true }]}>
                  <Input name="username" onChange={handleChange} className="Field" />
                </Form.Item>
              </Col>
              <Col span={24} md={24} className="input-login" >
                <span className="span-title-input">Password</span>
                <Form.Item name="password" rules={[{ required: true }]}>

                  <Input.Password name="password" onChange={handleChange} className="Field" />
                </Form.Item>
              </Col>
              <Col span={24} md={24} className="button-sign"  >
                <Form.Item >
                  <Button className="sing-in-btn" onClick={onFinish}>
                    {!loader ?
                      <span> Sign In</span>
                      :
                      <Spin indicator={antIcon} />
                    }
                  </Button>
                </Form.Item>
              </Col>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Login;
