import {
    ADD_TICKER_SUCCESS,
    ADD_TICKER_FAIL,
    UPDATE_TICKER_SUCCESS,
    UPDATE_TICKER_FAIL
} from '../actions/type'
let INIT_STATE = {
    isTicker : false,
    errors: null
}
const TickerReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_TICKER_SUCCESS : 
        return {
            ...state,
            isTicker : true,
            errors: null
        };
        case ADD_TICKER_FAIL :
            return {
                ...state,
                isTicker: false,
                errors: action.payload
            };
            case UPDATE_TICKER_SUCCESS : 
            return {
                ...state,
                isTicker : true,
                errors: null
            };
            case UPDATE_TICKER_FAIL :
                return {
                    ...state,
                    isTicker: false,
                    errors: action.payload
                };
        default:
            return state
    }
}
export default TickerReducer