import React, { useState, useEffect } from 'react'
import { Card, Button, Row, Col, Divider, Badge, Checkbox, Pagination, Image } from "antd";
import IconPlus from "../../Assets/icons/add-2.svg"
import "./style.css"
import { Link } from "react-router-dom";
import { getAllTvs, getSelectedTvs } from "../../Redux/actions/TvsAction"
import { useDispatch, useSelector } from 'react-redux'
import Topbar from '../../layout/Topbar';
import SkeletonCard from '../Skeleton/SkeletonCard';
import NotFound from "../../Assets/Images/Search engines-bro.svg"


const { Meta } = Card;

function TvDashboard() {
  const [checked, setChecked] = useState([])
  const Tvs = useSelector(state => state.Tvs.Tvs)
  const [TvsList, setTvsList] = useState([]);
  const [Disabled, setDisabled] = useState(false)
  const [minIndex, setMinIndex] = useState(0)
  const [maxIndex, setMaxIndex] = useState(9)
  const [search, setSearch] = useState("");

  // console.log("search",  search)
  const numEachPage = 9
  const dispatch = useDispatch()

  function uniq(a) {
    return a.sort().filter(function (item, pos, ary) {
      return !pos || item != ary[pos - 1];
    });
  }

  function onChange(e) {
    checked.push(e.target.value)
    setChecked(checked)
    var uniqueNames = uniq(checked);

    dispatch(getSelectedTvs(!uniqueNames.length > 1 ? uniqueNames : uniqueNames.join('&Ids=')))
    if (uniqueNames !== []) {
      setDisabled(true)
    }
  }
  const onClickCardAdd = () => {
    window.location.href = "/dashboard/add-NewTv"
    localStorage.removeItem('DocSelected')

  }
  const handleChange = (page) => {
    setMinIndex((page - 1) * numEachPage)
    setMaxIndex(page * numEachPage)
  }

  const onClickTv = (e) => {
    localStorage.setItem('IdtvSelected', e.target.id)
    checked.push(e.target.value)
    setChecked(checked)
    dispatch(getSelectedTvs(!checked.length > 1 ? checked : checked.join('&Ids=')))

  }
  useEffect(() => {
    dispatch(getAllTvs())
  }, [])

  useEffect(() => {
    if (Tvs) {
      const TvsResult = Tvs && Tvs.resultData && Tvs.resultData.results
      setTvsList(TvsResult)
    }
  }, [Tvs])

  return (
    <div>
      <Topbar TvsList={TvsList}
        search={search}
        setSearch={setSearch}
        Tvs={Tvs}
        setTvsList={setTvsList} />
      <div className="tv-Container">

        <Row gutter={[24]} justify="space-between" align="bottom" className="flex-end-contenttv" >
          <Col span={24} md={12} >
            <Card
              onClick={onClickCardAdd}
              bodyStyle={{
                padding: "4em",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                cursor: "pointer"

              }}
            >
              <div >
                <div className="Rectangle-add-tv">
                  <img src={IconPlus} alt="IconPlus" className="plus-icon" />
                </div>
                <h5 className="add-new-tv-title">Add New TV</h5>
              </div>
            </Card>


          </Col>
          <Col span={24} md={12} style={{ textAlign: "right" }}>
            <Link to="/dashboard/Edit-Tvs">
              <Button classsName="Rectangle-41-butntv-edit" disabled={!Disabled}
                style={{
                  backgroundColor: !Disabled ? "gray " : "#8dbbdb",
                  opacity: !Disabled ? "0.5 " : "1",
                  border: !Disabled ? "none " : "solid 1px #8dbbdb"
                }}>Edit multiple screens</Button>
            </Link>

          </Col>
        </Row>

        {/* </div> */}
        {/* <div className="grid-item-cardtv"> */}
        <Row gutter={[24, 0]} className={TvsList && !TvsList.length > 0 ? "flex-not-found" : null} >

          {TvsList ? (
            TvsList.length > 0 ? TvsList.slice(minIndex, maxIndex).map((item, index) => (
              <Col span={12} xs={24} sm={12} md={12} lg={8} xl={8} xxl={8} className="mb-24 card-index-tvs-container" key={index}>
                <Card
                  key={item.id}
                  // style={{ width: 400 }}
                  cover={
            
                      <Image
                        src={`https://api-hubersuhnercast.sane.agency/${item.displayedImage}`}
                        style={{
                          marginTop: "-8%", maxWidth: "93%",
                          //  height: "auto",
                          height: "29vh",
                          borderRadius: "10px",
                          objectFit: "cover"
                        }}
                      />
    
                

                  }
                >
                  <Meta
                    title={item.name}
                    description={<div>
                      {item.isActive ?
                        <Badge color="green" text="active" />
                        :
                        <Badge color="red" text="Inactive" />
                      }
                    </div>}
                  />
                  <Divider />
                  <Row gutter={[24, 0]} justify="space-between" align="middle"  >
                    <Col span={24} md={12} >
                      <Checkbox name={index} value={item.id} onChange={onChange} />
                    </Col>
                    {/* <div > */}

                    <Col span={24} md={12} className="ml-auto">

                      <Link to="/dashboard/Edit-Tv" >
                        <div onClick={onClickTv}>
                          <p id={item.id}
                            className="font-semibold text-muted mb-0 Edit-link-tv"
                            style={{ textAlign: "right" }}>{`Edit >`}
                          </p>
                          {/* <RightOutlined id={item.id} style={{ marginLeft: "0.5em" }} /> */}
                        </div>

                      </Link>

                    </Col>
                    {/* </div> */}

                  </Row>
                </Card>
              </Col>

            ))
              : <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}>
                <img src={NotFound} alt="not found" style={{ width: "100%", marginTop: "4em" }} />
                <span className="Not-found-text">No exact matches found</span></div>
          )
            : <SkeletonCard />}
        </Row>
        <Pagination
          defaultCurrent={1}
          defaultPageSize={numEachPage} //default size of page
          onChange={handleChange}
          total={TvsList && TvsList.length} //total number of card data available
          style={{ bottom: "0px", textAlign: "center", marginTop: "6em" }}
        />
        {/* </div> */}
       
      </div>
    </div>

  )
}

export default TvDashboard