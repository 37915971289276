import React from 'react'
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import routes from '../utils/routes';

function RouteMain({ match }) {

  return (
    <div>
                {routes.map(({ component: Component, path, exact }) => (

                  <PrivateRoute path={`/dashboard/${path}`}  
                   key={path}
                  exact={exact} 
                  component={Component} />
       
                  ))}
      {/* <PrivateRoute path="/dashboard" element={({ match }) => (
        <Switch>
          {routes.map(({ component: Component, path, exact }) => (
            <Route
              path={`${match.url}/${path}`}
              key={path}
              exact={exact}
            >
              <Component />
            </Route>
          ))}
          
        </Switch>
      )}
      /> */}
    </div>
  )
}

export default RouteMain