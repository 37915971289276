import {
    UPLOAD_MUSIC_SUCCESS,
    UPLOAD_MUSIC_FAIL,
    PAUSE_MUSIC_SUCCESS,
    PAUSE_MUSIC_FAIL,
    PLAY_MUSIC_SUCCESS,
    PLAY_MUSIC_FAIL
} from '../actions/type'
let INIT_STATE = {
    Music: null,
    errors: null,
    Pause: false,
    Play: false
}
const MusicReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPLOAD_MUSIC_SUCCESS:
            return {
                ...state,
                Music: action.payload.resultData,
                errors: null
            };
        case UPLOAD_MUSIC_FAIL:
            return {
                ...state,
                Music: null,
                errors: action.payload
            };
        case PAUSE_MUSIC_SUCCESS:
            return {
                ...state,
                Pause: action.payload.resultData,
                errors: null
            };
        case PAUSE_MUSIC_FAIL:
            return {
                ...state,
                Pause: false,
                errors: action.payload
            };
        case PLAY_MUSIC_SUCCESS:
            return {
                ...state,
                Play: action.payload.resultData,
                errors: null
            };
        case PLAY_MUSIC_FAIL:
            return {
                ...state,
                Play: false,
                errors: action.payload
            };
        default:
            return state
    }
}
export default MusicReducer