/**********************LogIn*****************************/
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGIN_TV_SUCCESS = 'LOGIN_TV_SUCCESS'
export const LOGIN_TV_FAIL = 'LOGIN_TV_FAIL'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGOUT_FAIL'

/**********************TVS*****************************/
export const LOAD_TVS_SUCCESS = 'LOAD_TVS_SUCCESS'
export const LOAD_TVS_FAIL = 'LOAD_TVS_FAIL'
export const SELECTED_TVS_SUCCESS = 'SELECTED_TVS_SUCCESS'
export const SELECTED_TVS_FAIL = 'SELECTED_TVS_FAIL'
export const GET_TV_SUCCESS = 'GET_TV_SUCCESS'
export const GET_TV_FAIL = 'GET_TV_FAIL'
export const ACTIVE_TVS_SUCCESS = 'ACTIVE_TVS_SUCCESS'
export const ACTIVE_TVS_FAIL = 'ACTIVE_TVS_FAIL'
export const EDIT_TVSNAME_SUCCESS = 'EDIT_TVSNAME_SUCCESS'
export const EDIT_TVSNAME_FAIL = 'EDIT_TVSNAME_FAIL'


/**********************DOCUMENTS*****************************/
export const UPLOAD_DOC_SUCCESS = 'UPLOAD_DOC_SUCCESS'
export const UPLOAD_DOC_FAIL = 'UPLOAD_DOC_FAIL'
export const ADD_DOC_SUCCESS = 'ADD_DOC_SUCCESS'
export const ADD_DOC_FAIL = 'ADD_DOC_FAIL'
export const LOAD_DOC_SUCCESS = 'LOAD_DOC_SUCCESS'
export const LOAD_DOC_FAIL = 'LOAD_DOC_FAIL'
export const DELETE_DOC_SUCCESS = 'DELETE_DOC_SUCCESS'
export const DELETE_DOC_FAIL = 'DELETE_DOC_FAIL'

/**********************Ticker*****************************/
export const ADD_TICKER_SUCCESS = 'ADD_TICKER_SUCCESS'
export const ADD_TICKER_FAIL = 'ADD_TICKER_FAIL'
export const UPDATE_TICKER_SUCCESS = 'UPDATE_TICKER_SUCCESS'
export const UPDATE_TICKER_FAIL = 'UPDATE_TICKER_FAIL'
/**********************Music*****************************/
export const UPLOAD_MUSIC_SUCCESS = 'UPLOAD_MUSIC_SUCCESS'
export const UPLOAD_MUSIC_FAIL = 'UPLOAD_MUSIC_FAIL'
export const PAUSE_MUSIC_SUCCESS = 'PAUSE_MUSIC_SUCCESS'
export const PAUSE_MUSIC_FAIL = 'PAUSE_MUSIC_FAIL'
export const PLAY_MUSIC_SUCCESS = 'PLAY_MUSIC_SUCCESS'
export const PLAY_MUSIC_FAIL = 'PLAY_MUSIC_FAIL'
/*****************************Loader*******************************/
export const SHOW_LOADER = 'SHOW_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'

