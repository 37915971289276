import React, { useState, useEffect } from 'react'
import { Row, Col, Input, Button, Image, Spin } from "antd";
import animation1 from "../../Assets/icons/fast-forward1.svg"
import animation2 from "../../Assets/icons/fast-forward2.svg"
import animation3 from "../../Assets/icons/fast-forward3.svg"
import animation4 from "../../Assets/icons/fast-forward4.svg"
import animation5 from "../../Assets/icons/blur1.svg"
import animation6 from "../../Assets/icons/blur2.svg"
import AddMedia from './addContent/AddMedia';
import { UploadDocument, GetDocument, DeleteDocument } from "../../Redux/actions/DocumentAction"
import { useDispatch, useSelector } from 'react-redux'
import AddMusic from './addContent/AddMusic';
import { GetActive, getTvById, EditName } from "../../Redux/actions/TvsAction"
import SelectedTvs from './containers/SelectedTvs';
import DocumentsList from './containers/DocumentsList';
import videoImg from "../../Assets/Images/videopn.png"
import { EditOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
import imageTvPlay from "../../Assets/Images/play-button.svg"

function EditScreens() {

    const [checked, setChecked] = useState(false)
    const dispatch = useDispatch()
    const ListAnimation = [
        animation1, animation2, animation3, animation4, animation5, animation6
    ]
    const SelectedTv = JSON.parse(localStorage.getItem("DocSelected"))
    const Document = useSelector(state => state.Doc.Document)
    const IdtvSelected = localStorage.getItem("IdtvSelected")
    const TVs = useSelector(state => state.Tvs.TvId)
    const Errors = useSelector(state => state.Tvs.errors)
    const isActive = TVs && TVs.resultData && TVs.resultData.isActive
    const [switcher, setSwitcher] = useState(isActive)
    const [showEdit, setShowEdit] = useState(false)
    const [loader, setLoader] = useState(false)
    const [Name, setName] = useState({})
    const onChange = e => {
        setChecked(
            e.target.checked,
        );
    };

    function onChangeSwitch(checked) {
        setSwitcher(checked)

    }
    const OnClickSwitch = () => {
        dispatch(GetActive(IdtvSelected, switcher))
    }

    const props = {
        name: 'file',
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            const files = info && info.file ? info.file.originFileObj : null;
            const formData = new FormData();
            formData.append("files", files);
            dispatch(UploadDocument(formData))
            // if (info.file.status !== 'uploading') {
            //     console.log(info.file, info.fileList);
            // }
            // if (info.file.status === 'done') {
            //     message.success(`${info.file.name} file uploaded successfully`);
            // } else if (info.file.status === 'error') {
            //     message.error(`${info.file.name} file upload failed.`);
            // }
        },
    };
    const EditTVName = () => {
        setShowEdit(true)
        setLoader(false)

        if (showEdit) {
            setShowEdit(false)
        }

    }
    const OnChangeEditName = (e) => {
        setName({ ...Name, [e.target.name]: e.target.value })

    }
    const OnClickEditName = () => {
        setLoader(true)
        if (Name.userName) {
            dispatch(EditName(IdtvSelected, Name))
            setLoader(false)

        }
        setShowEdit(false)
    }
    const dataList = []
    if (SelectedTv) {
        SelectedTv.map((ex, i) => {
            dataList.push({
                key: (i + 1).toString(),
                keyPath: i.toString(),
                title: `${ex.name}`,
                id: `${ex.id}`
            })

        })
    }
    console.log("Name***", Name)
    // let IDTVS = SelectedTv && dataList && current && dataList.filter(e => e.keyPath === current)[0].id
    const DOCList = []
    if (Document) {
        Document.map((ex, i) => {
            DOCList.push({
                key: (i + 1).toString(),
                keyPath: i.toString(),
                name: `${ex.displayName}`,
                id: `${ex.id}`

            })
        })
    }
    const DeleteDoc = (event) => {
        dispatch(DeleteDocument(event.target.id))
    }
    useEffect(() => {
        dispatch(GetDocument(IdtvSelected))
        dispatch(getTvById(IdtvSelected))

    }, [IdtvSelected, Document])

    useEffect(() => {
    }, [DOCList, isActive])
    //     useEffect(() => {
    // if( Errors !== null ? true : false === true){
    //     setShowEdit(true)
    //     setLoader(false)
    //     // message.error(Errors ? Errors.errors.userName[0] : null);
    // }


    // // setLoader(false)
    //     }, [Errors, loader])
    // console.log("loader**********", loader) 
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return (
        <div>
            <Row gutter={[24]} justify="space-between" align="middel" >
                <Col span={24} md={8} style={{ display: "flex", flexDirection: "column" }} >
                    <Row gutter={[24]} justify="space-between" align="middel" >
                        <Col span={24} md={24} style={{ padding: 0, marginTop: "-4.8em" }} >
                            <div className="edit-Huber-container">
                                {!showEdit ?
                                    <h4 className="TV-Huber-name" style={{ marginBottom: "0.5em" }}>
                                        {TVs && TVs.resultData && TVs.resultData.name}</h4>
                                    :
                                    <Input.Group compact style={{ padding: "0 1em" }}>
                                        <Input
                                            style={{
                                                width: 'calc(100% - 61px)',
                                            }}
                                            name="userName"
                                            onChange={OnChangeEditName}
                                            defaultValue={TVs && TVs.resultData && TVs.resultData.name} />
                                        <Button type="primary" onClick={OnClickEditName}>
                                            {!loader ?
                                                <span> Save</span>
                                                :
                                                <Spin indicator={antIcon} />
                                            }</Button>
                                    </Input.Group>
                                    // <Input defaultValue={TVs && TVs.resultData && TVs.resultData.name} placeholder="Basic usage" />
                                }
                                <EditOutlined onClick={EditTVName} style={{ fontSize: "1.5em" }} />
                            </div>
                            <Image
                        src={`https://api-hubersuhnercast.sane.agency/${TVs && TVs.resultData && TVs.resultData.displayedImage}`}
                        style={{
                            maxWidth: "100%",
                            height: "32vh",
                            objectFit: "cover",
                            borderRadius: "16px",
                        }}
                      />
             
                           
                           
                        </Col>
                        <AddMusic IDTVS={IdtvSelected}
                            TVs={TVs && TVs} />
                    </Row>
                </Col>
                <Col span={24} md={16}  >
                    <DocumentsList
                        TVs={TVs}
                        SelectedTv={SelectedTv}
                        switcher={switcher}
                        isActive={isActive}
                        OnClickSwitch={OnClickSwitch}
                        onChangeSwitch={onChangeSwitch}
                        DOCList={DOCList}
                        DeleteDoc={DeleteDoc}
                        Document={Document} />
                </Col>
            </Row>
            <AddMedia
                props={props}
                checked={checked}
                onChange={onChange}
                ListAnimation={ListAnimation}
                TVId={IdtvSelected}
                TVsSelected={TVs && TVs.resultData}

            />
        </div>
    )
}

export default EditScreens