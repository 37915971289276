import {
    UPLOAD_MUSIC_SUCCESS,
    UPLOAD_MUSIC_FAIL,
    PAUSE_MUSIC_SUCCESS,
    PAUSE_MUSIC_FAIL,
    PLAY_MUSIC_SUCCESS,
    PLAY_MUSIC_FAIL
} from './type'
import axios from 'axios'
import { URL_TV } from "../../utils/GlobalURL"
import SetToken from '../../utils/SetToken'

export const UploadMusic = (data, config) => (dispatch) => {
    SetToken()
    axios.post(`${URL_TV}/tv-music/upload-music/${data.idtv}`, data.formData, config)
        .then(res => dispatch({
            type: UPLOAD_MUSIC_SUCCESS,
            payload: res.data

        }))
        .catch(err =>{
            if (err.response.status === 401) {
                window.location = "/"
           }
           return err,
           dispatch({
            type: UPLOAD_MUSIC_FAIL,
            payload: err.response
        })
        } )

}
export const PauseMusic = (MusicId) => (dispatch) => {
    SetToken()
    axios.put(`${URL_TV}/tv-music/Pause/${MusicId}`)
        .then(res =>{
            dispatch({
                type: PAUSE_MUSIC_SUCCESS,
                payload: res.data
    
            })
        } )
        .catch(err =>{
            if (err.response.status === 401) {
                window.location = "/"
           }
           return err,
           dispatch({
            type: PAUSE_MUSIC_FAIL,
            payload: err.response
        })
        } )

}
export const PlayMusic = (MusicId) => (dispatch) => {
    SetToken()
    axios.put(`${URL_TV}/tv-music/Play/${MusicId}`)
        .then(res => {
            dispatch({
                type: PLAY_MUSIC_SUCCESS,
                payload: res.data
    
            })
        }
         )
        .catch(err =>{
            if (err.response.status === 401) {
                window.location = "/"
           }
           return err,
           dispatch({
            type: PLAY_MUSIC_FAIL,
            payload: err.response
        })
        } )

}

