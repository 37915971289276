import {
    ADD_TICKER_SUCCESS,
    ADD_TICKER_FAIL,
    UPDATE_TICKER_SUCCESS,
    UPDATE_TICKER_FAIL
} from './type'
import axios from 'axios'
import { URL_TV } from "../../utils/GlobalURL"
import SetToken from '../../utils/SetToken'
import { notification } from "antd";

export const AddTickers = (data) => (dispatch) => {
    // console.log("data", data)
    SetToken()
    axios.post(`${URL_TV}/tv-ticker`, data)
        .then(res => {
            if (res) {
            
                dispatch({
                    type: ADD_TICKER_SUCCESS,
                    payload: res.data

                })
                notification["success"]({
                    message:
                        "Success !!",
                    description: "",
                })
            }

        })
        .catch(err => {
            if (err) {
                if (err.response.status === 401) {
                    window.location = "/"
               }
               return err,
                dispatch({
                    type: ADD_TICKER_FAIL,
                    payload: err.response
                }),
                notification["error"]({
                    message:
                        "failed !!",
                    description: "",
                })
            }

        })

}
export const EditTickers = (tickerId, data) => (dispatch) => {
    SetToken()
    axios.post(`${URL_TV}/tv-ticker/${tickerId}`, data)
        .then(res => dispatch({
            type: UPDATE_TICKER_SUCCESS,
            payload: res.data

        }))
        .catch(err =>{
            if (err.response.status === 401) {
                window.location = "/"
           }
           return err,
           dispatch({
            type: UPDATE_TICKER_FAIL,
            payload: err.response
        })
        } )

}
