import {
    UPLOAD_DOC_SUCCESS,
    UPLOAD_DOC_FAIL,
    ADD_DOC_SUCCESS,
    ADD_DOC_FAIL,
    LOAD_DOC_SUCCESS,
    LOAD_DOC_FAIL,
    DELETE_DOC_SUCCESS,
    DELETE_DOC_FAIL,

} from '../actions/type'
let INIT_STATE = {
    filePdf: null,
    isDoc: null,
    errors: null,
    Document: null,
    loader: true
}
const DocumentReducer = (state = INIT_STATE, action) => {
    switch (action.type) {

        case UPLOAD_DOC_SUCCESS:

            localStorage.setItem('filePdf', JSON.stringify(action.payload))
            return {
                ...state,
                filePdf: action.payload,
                errors: null
            };
        case UPLOAD_DOC_FAIL:
            localStorage.removeItem('filePdf')

            return {
                ...state,
                filePdf: null,
                errors: action.payload
            };
        case ADD_DOC_SUCCESS:
            return {
                ...state,
                isDoc: action.payload.resultData,
                errors: null,
                loader: false
            };
        case ADD_DOC_FAIL:
            return {
                ...state,
                isDoc: null,
                errors: action.payload,
                loader: false

            };
        case LOAD_DOC_SUCCESS:
            return {
                ...state,
                Document: action.payload.resultData,
                errors: null
            };
        case LOAD_DOC_FAIL:
            return {
                ...state,
                Document: null,
                errors: action.payload
            };
        case DELETE_DOC_SUCCESS:
            return {
                ...state,
                errors: null
            };
        case DELETE_DOC_FAIL:
            return {
                ...state,
                errors: action.payload
            };
            // case LOADER_FAIL:
            //     return {
            //         ...state,
            //         errors: null
            //     };
            // case LOADER_SUCCESS:
            //     return {
            //         ...state,
            //         errors: action.payload
            //     };
        default:
            return state
    }
}
export default DocumentReducer