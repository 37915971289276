import Home from "../components/Home/index"
import TvDashboard from "../components/TvDashboard/index"
import AddTv from '../components/TvDashboard/AddTv';
import EditMultipleScreens from "../components/TvDashboard/EditMultipleScreens";
import EditScreens from "../components/TvDashboard/EditScreens";
const routes = [
    {
        path: '',
        component: TvDashboard,
        exact: true
    },
    {
        path: 'add-NewTv',
        component: AddTv,
        exact: true
    },
    {
        path: 'TVs',
        component: Home,
        exact: true
    },
    {
        path: 'Edit-Tvs',
        component: EditMultipleScreens,
        exact: true
    },
    {
        path: 'Edit-Tv',
        component: EditScreens,
        exact: true
    }
];

export default routes;