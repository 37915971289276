import React from 'react'
import { Card, Row, Col, Menu, List } from "antd";
const { Meta } = Card;

function SelectedTvs({ dataList, handleClick, current }) {
    return (
        <Col span={24} md={24} style={{ padding: 0 }} className="card-list-selectedtv" >

            <Card
                // style={{ width: "90%" }}
                cover={
                    <Row gutter={[24]} justify="space-between" align="middel"
                        className="In-Active-title-select"
                        style={{ backgroundColor: "#0B77C2" }}>
                        <Col span={24} md={24}>
                            <span>Selected TVs</span>
                        </Col>
                    </Row>
                }
            >
                <Meta
                    title={
                        <List
                            size="small"
                            dataSource={dataList}
                            renderItem={(item) => (
                                <List.Item>
                                    <Menu
                                        mode="inline"
                                        openKeys={item.keyPath}
                                        onClick={handleClick}
                                        selectedKeys={[current]}


                                    //  onOpenChange={onOpenChange}
                                    >
                                        <Menu.Item key={item.keyPath} style={{ display: "flex", flexDirection: "row" }}>
                                            <span style={{ marginRight: "1em" }}>{item.key}</span>
                                            <span>{item.title}</span>
                                        </Menu.Item>
                                    </Menu>
                                </List.Item>
                            )}
                        // renderItem={item => <List.Item>{item}</List.Item>}
                        />
                    }
                />

            </Card>
        </Col>
    )
}

export default SelectedTvs