import React, { useState, useEffect } from 'react'
import { Card, Row, Col, List, Switch, Divider } from "antd";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DeleteIcon from "../../../Assets/icons/x.svg"
import InfiniteScroll from 'react-infinite-scroll-component';

const { Meta } = Card;

function DocumentsList({ TVs, SelectedTv, isActive, switcher, OnClickSwitch, onChangeSwitch, DOCList, DeleteDoc }) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const loadMoreData = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        fetch('https://randomuser.me/api/?results=10&inc=name,gender,email,nat,picture&noinfo')
            .then(res => res.json())
            .then(body => {
                setData([...data, ...body.results]);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };
    function handleOnDragEnd(result) {
        if (!result.destination) return;
        const [reorderedItem] = DOCList.splice(result.source.index, 1);
        DOCList.splice(result.destination.index, 0, reorderedItem);

    }
    useEffect(() => {
        loadMoreData();

    }, [switcher]);
//   const isActive= TVs && TVs.resultData && TVs.resultData.isActive
    useEffect(() => {
        if(window.location.pathname === "/dashboard/add-NewTv"){
                localStorage.removeItem('DocSelected')
      }
             }, [])
            //  console.log("SelectedTv", SelectedTv)
    return (
        <Col span={24} md={24} style={{ marginTop:  "1.5em"  }} >
            <Card
                className='container-card-list-doc'
                style={{ width: "100%" }}
                cover={
                    <Row gutter={[24]} justify="space-between" align="middel"
                        className="In-Active-title"
                        style={{ backgroundColor: "#4AA54F"  }}>
                        <Col span={24} md={12}>
                            {/* {isActive && switcher ? */}
                                <span>Active</span>
                                {/* :
                                <span>Inactive</span>
                            } */}
                        </Col>
                        {/* <Col span={24} md={12} style={{ textAlign: "end" }}>
                            <Switch defaultChecked={switcher} onClick={OnClickSwitch} onChange={onChangeSwitch} />
                        </Col> */}
                    </Row>
                }
            >
                <Meta

                    title={
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId="DropId">
                                {(provided) => (
                                    <div
                                        id="scrollableDiv"
                                        style={{
                                            height: "auto",
                                            maxHeight: 400,
                                            overflow: 'auto'
                                        }}
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}>
                                        <InfiniteScroll
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                            dataLength={data.length}
                                            next={loadMoreData}
                                            hasMore={data.length < 50}
                                            loader={null}
                                            endMessage={!SelectedTv && !data ? <Divider plain>It is all, nothing more 🤐</Divider> : null}
                                            scrollableTarget="scrollableDiv"
                                        >
                                            <List
                                                size="small"
                                                dataSource={ DOCList }
                                                rowKey="Id"
                                                renderItem={(item, index) => (
                                                    <Draggable draggableId={`draggable-${index}`} index={index}>
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}

                                                            >
                                                                <List.Item
                                                                    actions={[<img style={{ width: "70%" }} src={DeleteIcon} id={item.id} alt="Delete" onClick={DeleteDoc} />]}
                                                                >
                                                                    <List.Item.Meta
                                                                        avatar={<span style={{ color: "#4AA54F" , marginRight: "1em" }}>
                                                                            {index + 1}</span>}
                                                                        title={<span  {...provided.dragHandleProps}
                                                                         style={{ color: "#4E4E4E"  }}>
                                                                            {item.name}</span>
                                                                        }
                                                                    />

                                                                </List.Item>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )}
                                            />
                                        </InfiniteScroll>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    }
                />
         
            </Card>
        </Col>
    )
}

export default DocumentsList