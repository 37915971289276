import React, { useState, useEffect } from 'react'
import { Row, Col } from "antd";
import animation1 from "../../Assets/icons/fast-forward1.svg"
import animation2 from "../../Assets/icons/fast-forward2.svg"
import animation3 from "../../Assets/icons/fast-forward3.svg"
import animation4 from "../../Assets/icons/fast-forward4.svg"
import animation5 from "../../Assets/icons/blur1.svg"
import animation6 from "../../Assets/icons/blur2.svg"
import AddMedia from './addContent/AddMedia';
import axios from 'axios'
import { UploadDocument, GetDocument, DeleteDocument } from "../../Redux/actions/DocumentAction"
import { useDispatch, useSelector } from 'react-redux'
import AddMusic from './addContent/AddMusic';
import { GetActive, getTvById } from "../../Redux/actions/TvsAction"
import SelectedTvs from './containers/SelectedTvs';
import DocumentsList from './containers/DocumentsList';
function EditMultipleScreens() {
    const [checked, setChecked] = useState(false)
    const [current, setCurrent] = useState("0")
    const dispatch = useDispatch()
    const ListAnimation = [
        animation1, animation2, animation3, animation4, animation5, animation6
    ]
    const SelectedTv = JSON.parse(localStorage.getItem("DocSelected"))
    const Document = useSelector(state => state.Doc.Document)
    const TVs = useSelector(state => state.Tvs.TvId)
    const isActive= TVs && TVs.resultData && TVs.resultData.isActive
    const [switcher, setSwitcher] = useState(isActive)

    const onChange = e => {

        setChecked(
            e.target.checked,
        );
    };

    function onChangeSwitch(checked) {
        setSwitcher(checked)

    }
    const OnClickSwitch = () => {
        dispatch(GetActive(IDTVS, switcher))
    }

    const props = {
        name: 'file',
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            const files = info && info.file ? info.file.originFileObj : null;
            const formData = new FormData();
            formData.append("files", files);
            dispatch(UploadDocument(formData))
            // if (info.file.status !== 'uploading') {
            //     console.log(info.file, info.fileList);
            // }
            // if (info.file.status === 'done') {
            //     message.success(`${info.file.name} file uploaded successfully`);
            // } else if (info.file.status === 'error') {
            //     message.error(`${info.file.name} file upload failed.`);
            // }
        },
    };
    const handleClick = e => {
        setCurrent(e.key)

    };
    // function getUnique(array){
    //     var uniqueArray = [];
    //     // Loop through array values
    //     for(var value of array){
    //         if(uniqueArray.indexOf(value) === -1){
    //             uniqueArray.push(value);
    //         }
    //     }
    //     return uniqueArray;
    // }
 
    const dataList = []
    if (SelectedTv) {
        SelectedTv.map((ex, i) => {
            dataList.push({
                key: (i + 1).toString(),
                keyPath: i.toString(),
                title: `${ex.name}`,
                id: `${ex.id}`
            })

        })
    }


    let IDTVS =  dataList && current && dataList.filter(e => e.keyPath === current)[0].id
   
    const DOCList = []
    if (Document) {
        Document.map((ex, i) => {
            DOCList.push({
                key: (i + 1).toString(),
                keyPath: i.toString(),
                name: `${ex.displayName}`,
                id: `${ex.id}`

            })
        })
    }
    const DeleteDoc = (event) => {
        dispatch(DeleteDocument(event.target.id))
    }
   
    useEffect(() => {
        // const CancelToken = axios.CancelToken;
        // const source = CancelToken.source();
        dispatch(GetDocument(IDTVS))
        return () => {
            // cancel the request before component unmounts
            // source.cancel();
            // Document.cancelRequest()
        };

    },[Document]
    //  [
    //     Document, TVs, IDTVS
    // ]
    )
useEffect(() => {
   
    dispatch(getTvById(IDTVS))

}, [TVs])
useEffect(() => {
    
}, [Document, TVs, IDTVS ])

    useEffect(() => {
    }, [DOCList , dataList, isActive])
// console.log("Document", Document)
// console.log("TVs", TVs)
// console.log("IDTVS", IDTVS)



    return (
        <div>

            <Row gutter={[24, 24]} justify="space-between" align="middel" >
                <Col span={24} md={8} style={{ display: "flex", flexDirection: "column" }} >
                    <Row gutter={[24]} justify="space-between" align="middel" >
                        <SelectedTvs dataList={dataList}
                            handleClick={handleClick}
                            current={current} />
                        <AddMusic IDTVS={IDTVS} 
                        TVs={TVs}/>
                    </Row>
                </Col>
                <Col span={24} md={16}  >
                    <DocumentsList
                        TVs={TVs}
                        SelectedTv={SelectedTv}
                        switcher={switcher}
                        isActive={isActive}
                        OnClickSwitch={OnClickSwitch}
                        onChangeSwitch={onChangeSwitch}
                        DOCList={DOCList}
                        DeleteDoc={DeleteDoc}
                        Document={Document} />
                </Col>
            </Row>
            <AddMedia
                props={props}
                checked={checked}
                onChange={onChange}
                ListAnimation={ListAnimation}
            />
        </div>
    )
}

export default EditMultipleScreens