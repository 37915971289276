import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Input, Form, Spin, notification } from "antd";
import { useDispatch } from 'react-redux'
import { AddTvs } from "../../Redux/actions/Auth"
import { LoadingOutlined } from '@ant-design/icons';
import PasswordChecklist from "react-password-checklist"
import { useHistory } from 'react-router-dom';

function RegisterTv({TVs }) {
    // console.log("props", TVs)
    const dispatch = useDispatch()
    let history = useHistory();

    const [loader, setLoader] = useState(false)
    const [valid, setValid] = useState(false)

    const [value, setValue] = useState({
        username: "",
        password: ""
    })
    const handleChange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value })
    }
    const OnSubmit = () => {
        if(valid === true){
            setLoader(true)
            dispatch(AddTvs(value))
        }
        else {
            notification["error"]({
                message:
                    "your password should have more than 6 characters. please try again",
                description: "",
            })
        }
     

    }
    useEffect(() => {
        if (TVs) {
            setLoader(false)
            history.push("/dashboard");

        } else if (TVs === undefined) {
            setLoader(false)

        }

    }, [TVs])

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return (

        <Row gutter={[24]} justify="space-between" align="middel" style={{ display:"flex", alignItems:"center" }} >
            <Form style={{ width: "100%" }} >

                <Col span={24} md={24} style={{ marginBottom: "1em" }}>
                    <Form.Item name="username" rules={[{ required: true }]}>
                        <Input placeholder="TV Name" style={{ height: "7.5vh", borderRadius: 5 }}
                            name="username" onChange={handleChange} />
                    </Form.Item>
                </Col>
                <Col span={24} md={24} style={{ marginBottom: "1em" }} >
                    <Form.Item name="password" rules={[{ required: true }]}>

                        <Input name="password" onChange={handleChange} placeholder="Password" style={{ height: "7.5vh", borderRadius: 5 }} />
                       {value.password? 

                            <PasswordChecklist
                            rules={["minLength"]}
                            minLength={6}
                            value={value.password}
                            onChange={(isValid) => { setValid(isValid) }}
                        />
                       : null}
                        
                    </Form.Item>
                </Col>
                <Col span={24} md={24} 
                // style={{
                //     display: "flex",
                //     justifyContent: "flex-end"
                // }}
                 >
                    <Form.Item className="addmedia-container-btn" >
                        <Button style={{ height: "7vh", width:"100%", marginTop: "1em" }} onClick={OnSubmit}
                        >
                            {!loader ?
                                <span> Submit</span>
                                :
                                <Spin indicator={antIcon} />
                            }
                        </Button>
                    </Form.Item>
                </Col>
            </Form>
            {/* <Col span={24} md={24} style={{ marginBottom: "1em" }}>
                <Input placeholder="TV Name" style={{ height: "6vh", borderRadius: 5 }}
                    name="username" onChange={handleChange} />

            </Col>
            <Col span={24} md={24} style={{ marginBottom: "1em" }} >
                <Input placeholder="Password" style={{ height: "6vh", borderRadius: 5 }}
                    name="password" onChange={handleChange} />

            </Col>
            <Col span={24} md={24} style={{
                display: "flex",
                justifyContent: "flex-end"
            }}
                className="addmedia-container-btn">
                <Button style={{ width: "11%", height: "6vh" }} onClick={OnSubmit}>Submit</Button>
            </Col> */}



        </Row>
    )
}

export default RegisterTv