import {
    LOAD_TVS_SUCCESS,
    LOAD_TVS_FAIL,
    SELECTED_TVS_SUCCESS,
    SELECTED_TVS_FAIL,
    GET_TV_SUCCESS,
    GET_TV_FAIL,
    ACTIVE_TVS_SUCCESS,
    ACTIVE_TVS_FAIL,
    EDIT_TVSNAME_SUCCESS,
    EDIT_TVSNAME_FAIL,
    SHOW_LOADER, 
    HIDE_LOADER 

} from '../actions/type'
let INIT_STATE = {
    Tvs: null, 
    errors: null,
    SelectedTv: null,
    TvId: null,
    isActive: null,
    NameTv: null,
    loading: false
}
const TvsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_TVS_SUCCESS : 
        return {
            ...state,
            Tvs : action.payload,
            errors: null
        };
        case LOAD_TVS_FAIL :
            return {
                ...state,
                Tvs: null,
                errors: action.payload
            };
            case GET_TV_SUCCESS : 
            return {
                ...state,
                TvId : action.payload,
                errors: null
            };
            case GET_TV_FAIL :
                return {
                    ...state,
                    TvId: null,
                    errors: action.payload
                };
            case SELECTED_TVS_SUCCESS : 
            localStorage.setItem('DocSelected', JSON.stringify(action.payload.resultData))

            return {
                ...state,
                SelectedTv : action.payload.resultData,
                errors: null
            };
            case SELECTED_TVS_FAIL :
                return {
                    ...state,
                    SelectedTv: null,
                    errors: action.payload
                };
                case ACTIVE_TVS_SUCCESS : 
                return {
                    ...state,
                    isActive : action.payload.resultData,
                    errors: null
                };
                case ACTIVE_TVS_FAIL :
                    return {
                        ...state,
                        isActive: false,
                        errors: action.payload
                    };
                    case EDIT_TVSNAME_SUCCESS: 
                    return {
                        ...state,
                        NameTv : action.payload.resultData,
                        errors: null
                    };
                    case EDIT_TVSNAME_FAIL:
                        return {
                            ...state,
                            NameTv: null,
                            errors: action.payload.data
                        };
                        case SHOW_LOADER : 
                        return {
                            ...state,
                            loading : true,
                        };
                        case HIDE_LOADER :
                            return {
                                ...state,
                                loading: false,
                            };
                      
        default:
            return state
    }
}
export default TvsReducer