import {
    UPLOAD_DOC_SUCCESS,
    UPLOAD_DOC_FAIL,
    ADD_DOC_SUCCESS,
    ADD_DOC_FAIL,
    LOAD_DOC_SUCCESS,
    LOAD_DOC_FAIL,
    DELETE_DOC_SUCCESS,
    DELETE_DOC_FAIL,
} from './type'
import axios from 'axios'
import { URL_TV } from "../../utils/GlobalURL"
import SetToken from '../../utils/SetToken'
import { notification } from "antd";

export const UploadDocument = (file, config) => (dispatch) => {
    // console.log("file***", file)
    
    SetToken()
    axios.post(`${URL_TV}/tv-document/upload-document-medias`, file,  config)
        .then((res) => {
            // console.log("res", res)
            dispatch({
                type: UPLOAD_DOC_SUCCESS,
                payload: res.data
            })
        }
        )
        .catch(err =>{
            if (err.response.status === 401) {
                window.location = "/"
           }
           return err,
            // console.log("res", err)
            dispatch({
                type: UPLOAD_DOC_FAIL,
                payload: err.response
            })
        } 
            // ,
            // notification["error"]({
            //     message:
            //       "your upload is failed",
            //     description: "",
            //   })
        )

}
export const AddDocument = (data) => (dispatch) => {
    // console.log("udata-", data)  
      SetToken()
    axios.post(`${URL_TV}/tv-document/add-document`, data)
        .then(res => {
            if (res) {
                // console.log("res", res)
                dispatch({
                    type: ADD_DOC_SUCCESS,
                    payload: res.data
                })
              
                notification["success"]({
                    message:
                        "Success !!",
                    description: "",
                })
            }

        })
        .catch(err => {
            if (err) {
                if (err.response.status === 401) {
                    window.location = "/"
               }
               return err,
                dispatch({
                    type: ADD_DOC_FAIL,
                    payload: err.response
                }),
              
                notification["error"]({
                    message:
                        "failed !!",
                    description: "",
                })
            }
            // console.log("res", err)


        })
}

export const GetDocument = (tvId) => (dispatch) => {
    SetToken()
    axios.get(`${URL_TV}/tv-document/get-documents/${tvId}`)
        .then(res => dispatch({
            type: LOAD_DOC_SUCCESS,
            payload: res.data
        }))
        .catch(err => {
            if (err.response.status === 401) {
                window.location = "/"
           }
           return err,
            dispatch({
            type: LOAD_DOC_FAIL,
            payload: err.response
        })})

}
export const DeleteDocument = (DocId) => (dispatch) => {
    SetToken()
    axios.delete(`${URL_TV}/tv-document/delete-document/${DocId}`)
        .then(res => dispatch({
            type: DELETE_DOC_SUCCESS,
            payload: res.data
        }))
        .catch(err => {
            if (err.response.status === 401) {
                window.location = "/"
           }
           return err,
           dispatch({
            type: DELETE_DOC_FAIL,
            payload: err.response
        })})

}

