import React from 'react'
import sidbarImage from "../Assets/Images/sidebarImage.svg"
import IconTvEye from "../Assets/icons/icontv-eye.svg"
import IconTV from "../Assets/icons/icontv.svg"
import IconLogOut from "../Assets/icons/logOutIcon.png"
import logo from "../Assets/icons/logoHuber.svg"
import { Menu, Divider } from 'antd';
import { Link } from "react-router-dom";

function Sidebar() {
    // const [key, setkey] = useState("1")

    const MenuItem = [
        {
            name: "TVs",
            key: "1",
            image: IconTV,
            Link: "/dashboard"

        },
        {
            name: "Watch TVs",
            key: "2",
            image: IconTvEye,
            Link: "/dashboard/TVs"
        },
        // {
        //     name: "LogOut",
        //     key: "3",
        //     image: IconLogOut,
        //     Link: "/login"

        // },
    ]
//     const keyFiltred= MenuItem.filter(e=> e.Link === window.location.pathname)[0].key
//     const LinkFiltred= MenuItem.filter(e=> e.Link === window.location.pathname)[0].Link
//     console.log("LinkFiltred", LinkFiltred)
//     useEffect(() => {
//    if(LinkFiltred){
//     setkey(keyFiltred)  
//  }
//     }, [keyFiltred])
//     console.log("key", key)
// const OnClickMenu=({ item, key, keyPath, domEvent })=>{
//     console.log("item", item)
//     console.log("key", key)
//     console.log("keyPath", keyPath)
//     console.log("domEvent", domEvent)
//     // if(keyFiltred === key){
//             if(window.location.pathname === "/dashboard/add-NewTv"){
//                     localStorage.removeItem('DocSelected')
//           }
        

//      }
//     console.log("keyFiltred === key", keyFiltred === key)
// }
 const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('isAuth')
    window.location.reload()
};
    return (
        <div>
            <img src={sidbarImage} alt="" className="image-background-sidebar" />
            <div style={{ color: "#fff", zIndex: 1000, position: "relative" }}>
                <div className="brand">
                    <img src={logo} alt="" style={{width:"85%"}}/>
                </div>
                <Divider style={{ backgroundColor: "#424344", padding: "0.05em" }} />
                <Menu
                    defaultSelectedKeys={['1']}
                    theme="dark"
                    // onClick={OnClickMenu}
                >
                    {MenuItem.map(item => (
                        <Menu.Item   key={item.key}
                         icon={
                            <img src={item.image} alt="" style={{ width: "12%" }} />
                              }>
                            <Link to={item.Link} style={{ backgroundColor: "transparent" }}>
                                <span className="TVs-span">{item.name}</span>

                            </Link>
                        </Menu.Item>

                    ))}
                        <Menu.Item  onClick={logout} 
                         icon={
                            <img src={IconLogOut} alt="" style={{ width: "12%" }} />
                              }>
                            {/* <Link to={item.Link} style={{ backgroundColor: "transparent" }}> */}
                                <span className="TVs-span">LogOut</span>

                            {/* </Link> */}
                        </Menu.Item>
                </Menu>

            </div>

        </div>
    )
}

export default Sidebar